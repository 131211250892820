.featured-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  max-width: 80vw;
  margin: 0 auto;
  padding: 40px 0;
}

.featured-title {
  font-size: 36px;
  margin-bottom: 24px;
  text-align: left;
}

.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.service-card {
  width: 250px;
  margin: 25px;
}

.service-image {
  width: 100%;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.service-name-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.service-name {
  font-size: 24px;
  margin: 16px 0 0;
  padding-left: 0;
  text-align: left;
}

.service-price {
  font-size: 18px;
  margin: 0 0 16px;
  padding-left: 0;
  text-align: left;
}

.service-image:hover ~ .service-name-link .service-name {
  text-decoration: underline;
}