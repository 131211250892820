.announcement-bar {
  background-color: #000000;
  padding: 8px 0;
  text-align: center;
}

.announcement-bar p {
  margin: 0;
  color: #ffffff;
}

.header-divider {
  height: 1px;
  background-color: #e5e5e5;
}

.header {
  position: sticky;
  top: 0px;
  background-color: white;
  padding: 16px 0;
  z-index: 1000;
}
.header-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.header-nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 30px;
}

.header-logo {
  font-size: 24px;
}

.header-logo-link {
  text-decoration: none;
  color: #333;
}

.header-menu-item {
  margin-left: 20px;
  margin-bottom: 0;
  padding-left: 20px;
  text-align: left;
}

.header-icons {
  display: flex;
  align-items: center;
}

.header-icon-link {
  text-decoration: none;
  color: #333;
  margin-left: 20px;
}

.header-icon-link .svg-inline--fa {
  font-size: 1.2rem;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  margin-right: 20px;
}

.disable-scroll {
  overflow: hidden;
}

.header-menu.open .header-menu-item {
  margin-bottom: 10px;
  padding: 0;
}

.header-menu-link {
  text-decoration: none;
  color: #333;
  font-size: 1.2em;
  padding-left: 20px;
  display: block;
  padding: 10px 0;
}

.header-menu-link:hover {
  color: #007bff;
}
.cart-icon-link {
  color: #333;
  text-decoration: none;
}

.cart-icon-container {
  position: relative;
  display: block;
  width: 28px;
  height: 28px;
  /* height: auto; */
  /* overflow: hidden; */
}

.material-icons{
  position: relative;
  top: 4px;
  z-index: 1;
  font-size: 24px;
}
.cart-items-count {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  font-size: 11px;
  border-radius: 50%;
  background: #d60b28;
  width: 16px;
  height: 16px;
  line-height:16px;
  display: block;
  text-align: center;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}




@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* @media (min-width: 768px) {

} */

@media (min-width: 768px) {
  .header-menu-open {
    display: none;
  }
  .header-menu-closed {
    display: none;
  }
  .header-menu-desktop {
    justify-content: center;
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 35vw;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .header-nav {
    position: relative;
  }

  .hamburger-menu {
    display: block;
    position: absolute;
    left: 16px;
  }

  .header-logo {
    position: absolute;
  }

  .header-icons {
    position: absolute;
    right: 16px;
  }

  .header-menu-open {
    left: 0;
  }

  .header-menu-link {
    font-size: 1.5rem;
  }
  .header-menu-closed {
    list-style: none;
    margin-right: 35vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 85px;
    background-color: white;
    width: 80vw;
    height: 100%;
    border-right: 1px solid #e5e5e5;
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 20px 20px 20px 0;
    transform: translateX(-100%);
  }

  .header-menu-open {
    list-style: none;
    margin-right: 35vw;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    background-color: #ffffff;
    width: 80vw;
    height: 100%;
    border-right: 1px solid #e5e5e5;
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 20px;
    /* transform: translateX(0);
    transition: transform 0.3s ease; */
  }

  .header-menu > li {
    margin-bottom: 10px;
  }
  .header-menu.slide-in {
    animation: slideIn 0.3s forwards;
  }
  .header-menu.slide-out {
    animation: slideOut 0.3s forwards;
  }

  .header-menu-desktop {
    display: none;
  }
}
