.footer {
  background-color: #333;
  color: white;
  padding: 40px 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  font-size: 24px;
  margin-bottom: 20px;
}

.footer-nav {
  margin-bottom: 20px;
}

.footer-menu {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.footer-menu-item {
  margin-right: 20px;
}

.footer-menu-link {
  text-decoration: none;
  color: white;
}

.footer-copyright {
  font-size: 14px;
}
