.slider-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.slider-image-wrapper {
  position: absolute;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;
}

.slider-image-wrapper.active {
  opacity: 1;
}

.slider-caption {
  position: absolute;
  left: 50%;
  bottom: 15%;
  transform: translateX(-50%);
  text-align: center;
  max-width: 80%;
}

.slider-caption h2 {
  color: #fff;
  font-size: 50px;
  margin: 0;
}

.slider-shop-all {
  background-color: transparent;
  border: 25px;
  color: #ffffff;
  padding: 14px 28px;
  display: inline-block;
  margin-top: 20px;
  font-size: 20px;
  cursor: pointer;
  border: 2px solid rgb(255, 255, 255);
}

.slider-shop-all:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
}

.slider-prev,
.slider-next {
  background-color: transparent; /* Change this line to remove the background */
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  border: none;
}

.slider-prev {
  left: 2%;
}

.slider-next {
  right: 2%;
}

@media screen and (max-width: 768px) {

  .slider-caption {
    width: 100%;
  }
  .slider-caption h2 {
    font-size: 40px;
    width: 80%;
    margin: auto;
  }

  .slider-shop-all {
    font-size: 1.2em;
    padding: 10px 20px;
  }

  .slider-container {
    height: 70vh;
  }

}