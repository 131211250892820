/* body {
  font-family: 'Arial', sans-serif;
  background-color: #ffffff;
  padding: 0 2rem;
  margin: 2rem auto;
} */

.cart-empty {
  text-align: center;
  padding: 2rem;
  font-size: 1.5rem;
  color: #777;
}

.cart {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  background: #FFFFFF;
}

.cart h2 {
  color: #232F3E;
  padding-bottom: 1rem;
  border-bottom: 1px solid #DDD;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid #DDD;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.cart-item-details {
  flex-grow: 1;
  margin-left: 1.5rem;
}

.cart-item-title {
  font-size: 1.1rem;
  color: #007185;
}

.cart-item-quantity {
  width: 50px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  font-size: 1rem;
  color: #333;
  margin: 1rem 0;
}

.cart-item-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #B12704;
}

.cart-total {
  text-align: right;
  margin-top: 2rem;
  font-size: 1.3rem;
  font-weight: bold;
  color: #333;
}

@media (max-width: 768px) {
  .cart {
    padding: 1rem;
  }

  .cart-item {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }

  .cart-item-image {
    margin-bottom: 1rem;
  }

  .cart-item-quantity {
    margin-bottom: 1rem;
  }

  .cart-total {
    text-align: center;
  }
}
