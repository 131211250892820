.product-page {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.product-image-container {
  flex: 1;
  max-width: 600px;
  margin-right: 2rem;
}

.product-image {
  width: 100%;
  height: auto;
}

.product-info {
  flex: 1;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.product-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.product-price {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.quantity-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.quantity-button {
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #f5f5f5;
  cursor: pointer;
}

.product-actions {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.add-to-cart {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  background-color: #ec7a00;
  transition: all 0.3s ease;
}
.buy-now {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  background-color: black;
  transition: all 0.3s ease;
  }
  
  .add-to-cart:hover {
    background-color: #fd901b;
  }
  .buy-now:hover {
  background-color: #333;
  }
  
  .product-details {
  font-size: 1rem;
  line-height: 1.5;
  }
  
  @media (max-width: 767px) {
  .product-page {
  flex-direction: column;
  align-items: center;
  }
  
  .product-image-container {
  margin-right: 0;
  margin-bottom: 2rem;
  }
  }